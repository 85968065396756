import { useEffect, useMemo, useReducer, useRef, useState } from "react";

import { Alert } from "../../../components/ui/Alert";
import { Switch } from "../../../components/ui/Form/Switch";
import { Input } from "../../../components/ui/Form/Input";
import { validadeCpf } from "../../../services/functions";
import { TermController } from "../../../services/controllers/global";
import { toast } from "react-toastify";
import { useMerchant } from "../../../store/global/useMerchant";
import { TriangleAlert } from "lucide-react";

const blockedStores = [
  '53914539000112',
  '45595884000102',
  '48501935000188',
  '29049628000163',
  '34855666000224',
  '50360190000126',
  '07263921000196',
  '30514695000190',
  '22716309000115',
  '11339070000195',
  '24905108000191',
  '38085054000170',
  '31068706000119',
  '20790474000191',
  '47121267000109',
  '39446557000196',
  '34855666000143',
  '49697289000139',
  '43117804000188',
]





export function LgpdAlert() {
  const [signed, setSigned] = useState(true);
  const merchant = useMerchant(st => st.data.merchant)
  const menloTerm = TermController('menlo')
  const reopenTime = useRef(null)

  const alert = Alert.store(st => st.data.alerts['lgpd'])

  const isBlocked = useMemo(() => blockedStores.includes(merchant.cnpj), [merchant])

  const [confirmation, setConfirmation] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_CPF':
        const cpfValid = validadeCpf(action.payload);
        return { ...state, cpf: action.payload, cpfValid };
      case 'TOGGLE_MENLO_TERM':
        return { ...state, menloTerm: !state.menloTerm };
      case 'TOGGLE_CONSENT_TERM':
        return { ...state, consentTerm: !state.consentTerm };
      default:
        throw new Error(`Ação desconhecida: ${action.type}`);
    }
  }, {
    cpf: '',
    cpfValid: false,
    menloTerm: false,
    consentTerm: false,
  });

  useEffect(() => {
    menloTerm.setSigned(setSigned)
  }, [])

  useEffect(() => {
    Alert.store.getState().actions.isOpen('lgpd', !signed)
  }, [signed])


  useEffect(() => {
    clearTimeout(reopenTime.current)

    if (!alert?.open && !signed) {
      const delayToReopen = isBlocked ? 0 : 1000 * 60 * 60

      reopenTime.current = setTimeout(() => {
        Alert.store.getState().actions.isOpen('lgpd', true)
      }, delayToReopen)
    }
    return () => {
      clearTimeout(reopenTime.current)
    }
  }, [alert, signed])


  async function handleSendSigning() {
    await menloTerm.sendSigning(confirmation)
    toast.success("Termo \"LGPD MENLO PAGAMENTOS LTDA.\" aceito!", { theme: "colored" })
    setSigned(true)
  }

  return (
    <Alert.Root name="lgpd">
      <Alert.Content className="!max-w-3xl">
        <Alert.Title>LGPD MENLO PAGAMENTOS LTDA.</Alert.Title>
        <Alert.Description className="text-right">
          São Paulo, 01 de outubro de 2024.
        </Alert.Description>
        <div className="flex flex-col gap-5 mb-6">
          <div className="flex flex-col gap-2">
            <p><strong>BRASIL PET COMÉRCIO E SERVIÇOS DE PET SHOP S.A.</strong> e <strong>DRA. MEI NÚCLEO DE MEDICINA VETERINÁRIA LTDA.</strong> em conjunto denominadas <strong>“Franqueadoras”</strong>, comunicam que realizaram a cessão dos créditos correspondentes aos Royalties e ao Fundo de Marketing, devidos pelas suas Franqueadas, para a <strong>MENLO PAGAMENTOS LTDA. (“Menlo”)</strong>, inscrita no CNPJ/MF sob o nº 49.001.608/0001-20, com sede na Rua Doutor Renato Paes de Barros, 33, Itaim Bibi, KPMG Tower, CEP 04530-904, na cidade de São Paulo, Estado de São Paulo.</p>
            <p>Neste sentido, todos os pagamentos relativos aos Royalties e ao Fundo de Marketing devem ser efetuados diretamente para a Menlo (e serão cobrados por ela), a partir de <strong>10 de outubro de 2024</strong>.</p>
            <p>Para que a cobrança aconteça, conforme descrito acima, e com a finalidade de realização de cobrança de direitos creditórios oriundos do contrato de franquia pela Menlo, as Franqueadoras precisarão disponibilizar/divulgar alguns dados da loja franqueada, tais como denominação social, endereço completo, eventuais dados sensíveis de seus sócios, fiadores, dados bancários e financeiros. Tanto as Franqueadoras quanto a Menlo são conhecedoras das disposições da Lei Geral de Proteção de Crédito – LGPD e se comprometeram contratualmente a cumprir os seus dispositivos.</p>
            <p>Contamos com a sua manifestação de vontade, através da assinatura desta correspondência, autorizando as Franqueadoras a divulgar para a Menlo os dados acima listados, com a finalidade específica de realizar a cobrança dos direitos creditórios, relativos aos Royalties e Fundo de Marketing devidos pela Franqueada.</p>
          </div>

          <div className="flex flex-col gap-2">
            <p>Atenciosamente,</p>
            <div className="flex flex-col">
              <p><strong>BRASIL PET COMÉRCIO E SERVIÇOS DE PET SHOP S.A.</strong></p>
              <p><strong>DRA. MEI NÚCLEO DE MEDICINA VETERINÁRIA LTDA.</strong></p>
            </div>
          </div>
          <div className="flex flex-col gap-5 rounded shadow-md border border-zinc-200/40 p-2 ">
            <div className="w-52">
              <Input.Root label="CPF" mask="999.999.999-99" onChange={(cpf) => setConfirmation({ type: 'SET_CPF', payload: cpf })} />
            </div>
            <div className="flex flex-col gap-2">
              <p><Switch.Root name="menlo-term" className="!w-12" onChange={(active) => setConfirmation({ type: 'TOGGLE_MENLO_TERM', payload: active })} label="Li e estou ciente da cessão dos créditos acima mencionados, para a Menlo." /></p>
              <p><Switch.Root name="consent-term" className="!w-12" onChange={(active) => setConfirmation({ type: 'TOGGLE_CONSENT_TERM', payload: active })} label="Li, estou ciente e consinto com a divulgação dos dados, conforme acima esclarecido." /></p>
            </div>
          </div>
        </div>
        <Alert.Actions>
          <Alert.Cancel>Cancelar</Alert.Cancel>
          <Alert.Confirm
            type="button"
            disabled={!confirmation.cpfValid || !confirmation.menloTerm || !confirmation.consentTerm}
            onClick={handleSendSigning}>Confirmar</Alert.Confirm>
        </Alert.Actions>
      </Alert.Content>
    </Alert.Root >
  )
}
