import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  Button,
  Box,
  Grid,
  TextField,
  MenuItem,
} from "@material-ui/core";
// Material ui - icones

import Drawer from "@mui/material/Drawer";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SidebarPet from "../../components/molecules/SidebarPet";
import Customer from "../../components/molecules/Customer";
import API from "../../services/api";
import BasePage from "../basePage";
import { getSellers } from "../../services/sellers";

import { Configurations } from "./Configurations";
import { Fechamento } from "./Fechamento";
import { Extrato } from "./Extrato";
import { Ranking } from "./Ranking";
import _ from "lodash";
import { Subscribers } from "./Subscribers";
import { Dashboard } from "./Dashboard";
import { useUser } from "../../store/global/useUser";
import { useMerchant } from "../../store/global/useMerchant";
import { AuthContext } from "../../providers/auth";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 300,
    cursor: "pointer",
    border: "1px solid #ccc",
  },
  filter: {
    margin: "30px 0",
    background: "#fff",
    width: "100%",
    borderRadius: "7px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "15px",
    border: "1px solid #ccd219",
  },
  input: {
    "& input": {
      padding: "15px",
      fontSize: "12px",
    },
    "& label": {
      top: "-4px",
    },
  },
  content: {
    "& .MuiSvgIcon-fontSizeMedium": {
      width: "50px",
      height: "50px",
      color: "#ccd219 !important",
    },
    "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
      width: "50px",
      height: "50px",
      color: "#ccd219 !important",
    },
    "& .MuiStepConnector-vertical": {
      marginLeft: "26px",
    },
    "& .MuiStepContent-root": {
      marginLeft: "26px",
    },
  },
  buttonWhatsApp: {
    color: "#fff",
    fontSize: "12px",
    borderRadius: "7px",
    border: "0",
    textTransform: "uppercase",
    padding: " 10px",
    backgroundColor: "#25d366",
    "&:hover": {
      backgroundColor: "#1dba58",
      border: 0,
      color: "#fff",
    },
  },
  exportButton: {
    border: "1px solid #CCD219",
    color: "#CCD219",
    textTransform: "capitalize",
    fontSize: "14px",
    cursor: "pointer",
    "&:hover": {
      border: "1px solid #CCD219",
      background: "#CCD219",
      color: "#ffffff",
    },
    "&:disabled": {
      cursor: "initial",
      background: "#a6a6a6",
      border: "1px solid #a6a6a6",
      color: "#ffffff",
    },
  },
  paper: {
    padding: theme.spacing(2),
    border: "1px solid #ccd219",
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100px",
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "30px",
    "& h6": {
      marginBottom: "-8px",
      color: "#000",
    },
    "& p": {
      margin: "0px",
      fontSize: "12px",
    },
  },
  pagination: {
    marginTop: "20px",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccd219",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 13,
    lineHeight: "42px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


function Assinaturas() {

  const { modules } = useContext(AuthContext)

  const userRole = useUser(st => st.data.role)
  const isMaster = userRole === 'master'
  const initialTab = isMaster ? 'dashboard' : 'subscribers';

  const { control } = useForm();
  const [subscriptions, setSubscriptions] = useState([]);
  const [dashboard, setDashboard] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [loadingExport, setLoadingExport] = useState(false);
  const [value, setValue] = useState(initialTab);
  const [firstData, setFirstData] = useState(null);
  const [lastData, setLastData] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [sellers, setSellers] = useState([]);
  const [seller, setSeller] = useState("");
  const [pageAgendamentos, setPageAgendamentos] = useState(false);
  const [skipAgendamentos, setSkipAgendamentos] = useState(0);
  const [plans, selectPlans] = useState([]);
  const [plan, selectPlan] = useState("");

  const [word, setWord] = useState("");
  const size = 250;
  const bgColor = "ffffff";
  const [qrCode, setQrCode] = useState("");

  const [activeStep, setActiveStep] = useState(0);

  const handleReset = () => {
    setActiveStep(0);
  };

  // Modal

  const date = new Date();
  const primeiroDia = moment(
    new Date(date.getFullYear(), date.getMonth(), 1),
  ).format("YYYY-MM-DD");
  const handleChangeIndex = (event, value) => setValue(value);

  useEffect(() => {
    setQrCode(
      `http://api.qrserver.com/v1/create-qr-code/?&size=${size}x${size}&bgcolor=${bgColor}&data=${word}`,
    );
  }, [word, size, bgColor]);

  const classes = useStyles();

  const getDashboard = async () => {
    try {
      const { data } = await API.get(
        `subscriptions/store/dashboard?dateFrom=${firstData || primeiroDia
        }&dateTo=${lastData || moment(date).format("YYYY-MM-DD")
        }&type=appointment`,
      );
      setDashboard(data.dashboard);
    } catch (err) {
      console.log("Erro:", err);
    }
  };


  const getSubscriptions = async () => {
    try {
      const { data } = await API.get(
        `subscriptions/store/realized-service?limit=50&skip=${skipAgendamentos}&dateFrom=${firstData || primeiroDia
        }&dateTo=${lastData || moment(date).format("YYYY-MM-DD")}`,
      );
      setSubscriptions(data.storeSubscriptionsAppointments);
      setPageAgendamentos(data.pagination);
      getDashboard();

      if (data.storeSubscriptionsAppointments) {
        setDisableButton(false);
      }
    } catch (err) {
      setDisableButton(false);
      console.log("Erro:", err);
    }
  };

  const agendamentosUpdatePagination = (event, newPage) => {
    setSkipAgendamentos(newPage - 1);
  };

  const handleClick = () => {
    setDisableButton(true);
    getSubscriptions();
  };

  function clickDrawer() {
    setDrawerOpen(!drawerOpen);
  }

  function closeDrawer() {
    setDrawerOpen(!drawerOpen);
    setCustomer(null);
    handleReset();
  }

  useEffect(() => {
    getSubscriptions();
  }, [skipAgendamentos]);

  // DRAWER

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSellers = async () => {
    const result = await getSellers();
    setSellers(result);
  };

  const handleSeller = (event, value) => {
    setSeller(value?.id);
  };

  const exporte = async () => {
    if (!loadingExport) {
      setLoadingExport(true);
      const { data } = await API.post("subscriptions/store/export", {
        filters: {
          dateFrom: firstData || primeiroDia,
          dateTo: lastData || moment(date).format("YYYY-MM-DD"),
        },
        type: {
          name: "subscription-appointments",
          fileFormat: "xlsx",
        },
      });
      setLoadingExport(false);
      window.open(data.report, "_blank").focus();
    }
  };


  const linkGenerator = async () => {
    try {
      const { data, status } = await API.post(
        "subscriptions/store/checkout/cart/new",
        {
          planId: plan,
          sellerId: seller,
          cluster: "1",
          petId: customer?.pet?.id,
        },
      );

      if (status === 200 || status === 201) {
        setWord(
          `https://assinatura.petlandbrasil.com.br/assinatura/pagamento?cartId=${data?.cart?._id}`,
        );
        setActiveStep(2);
      }
    } catch (err) {
      setDisableButton(false);
      console.log("Erro:", err);
    }
  };

  useEffect(() => {
    async function subscription() {
      if (activeStep === 1 && customer) {
        try {
          const {
            data: { availablePlan },
          } = await API.get(
            `subscriptions/store/checkout/available-plans?petId=${customer?.pet?.id}`,
          );
          selectPlans(availablePlan);
        } catch (err) {

          setDisableButton(false);
          console.log("Erro:", err);
        }
      }
    }
    subscription();
  }, [customer]);

  return (
    <BasePage
      pageTitle="Assinaturas"
      extendStyles={{
        main: {
          display: "flex",
          paddingTop: 0,
          flexDirection: "column",
          height: "calc(100% - 0px)",
        },
      }}
    >
      <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
        <div
          style={{
            background: "#ccd219",
            color: "#fff",
            padding: "10px",
            fontSize: "18px",
          }}
        >
          CRIAR ASSINATURA
        </div>

        <div className={classes.content} style={{ padding: "20px" }}>
          <Box sx={{ maxWidth: 560 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step
                active={
                  activeStep === 0 || activeStep === 1 || activeStep === 2
                }
              >
                <StepLabel>BUSQUE O CLIENTE</StepLabel>

                <StepContent>
                  {customer ? (
                    <Customer
                      setCustomer={setCustomer}
                      customer={customer}
                      handleReset={handleReset}
                    />
                  ) : (
                    <SidebarPet
                      setCustomer={setCustomer}
                      handleNext={handleNext}
                    />
                  )}
                </StepContent>
              </Step>
              <Step active={activeStep === 1 || activeStep === 2}>
                <StepLabel>ESCOLHA O PLANO</StepLabel>
                <StepContent>
                  <TextField
                    id="plano"
                    label="Planos"
                    name="plano"
                    variant="outlined"
                    required
                    fullWidth
                    select
                    onChange={({ target }) => selectPlan(target.value)}
                  >
                    {plans?.map((item) => (
                      <MenuItem
                        key={item.code}
                        value={item?.code}
                        selected={item?.code}
                      >
                        {item?.description} -{" "}
                        {item?.price.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Autocomplete
                    disableClearable
                    required
                    options={sellers}
                    loading
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Vendedor"
                        placeholder="Digite o nome do vendedor"
                        margin="normal"
                        variant="outlined"
                        onChange={handleSellers}
                      />
                    )}
                    onChange={handleSeller}
                  />
                  {seller && plan && (
                    <Button
                      style={{
                        color: "#fff",
                        background: "#CCD219",
                        padding: "7px 20px",
                      }}
                      onClick={() => linkGenerator()}
                    >
                      Gerar Link
                    </Button>
                  )}
                </StepContent>
              </Step>
              <Step>
                <StepLabel>ENVIE A COBRANÇA PARA O CLIENTE</StepLabel>
                <StepContent>
                  <div style={{ display: "flex" }}>
                    <img
                      src={`${qrCode}`}
                      alt="qrCode"
                      style={{ marginRight: "16px" }}
                    />
                    <div>
                      <p
                        style={{
                          cursor: "default",
                          margin: "0",
                          paddingBottom: "10px",
                          color: "#666666",
                          fontWeight: "600",
                          fontSize: "16px",
                        }}
                      >
                        Compartilhar
                      </p>
                      <Link
                        className={classes.buttonWhatsApp}
                        style={{ textDecoration: "none" }}
                        to={{
                          pathname: `https://wa.me/55${customer?.phone
                            .replace(/\D/g, "")
                            .trim()}?text=Olá ${customer?.owner}.
                                        Segue link para finalizar sua assinatura: ${word}%26 Qualquer dúvida continuo à disposição`,
                        }}
                        target="_blank"
                      >
                        Enviar por whatsapp
                      </Link>
                    </div>
                  </div>

                  <Link
                    style={{
                      textDecoration: "none",
                      paddingTop: "10px",
                      color: "#666666",
                      display: "block",
                    }}
                    to={{ pathname: `${word}` }}
                    target="_blank"
                  >
                    Link: {`${word}`}
                  </Link>
                </StepContent>
              </Step>
            </Stepper>
          </Box>
        </div>

        <div className={classes.footer} />
      </Drawer>
      <div
        style={{
          boxShadow: "0 6px 20px rgb(0 0 0 / 10%)",
          borderRadius: "7px",
          display: "flex",
        }}
      >
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeIndex}
          aria-label="disabled tabs example"
          style={{ flex: 1 }}
        >
          {isMaster && <Tab label="Dashboard" value="dashboard" />}
          <Tab label="Assinantes" value="subscribers" />
          <Tab label="Configurações" value="configuration" />
          {/* <Tab label="Fechamento" value="fechamento" /> */}
          {isMaster && <Tab label="Extrato" value="extrato" />}
        </Tabs>
        <Button
          disabled={!modules?.subscription?.schedule}
          variant="outlined"
          color="primary"
          onClick={() => clickDrawer()}
          className="disabled:opacity-50 disabled:grayscale"
          style={{


            border: "none",
            background: "#d05d04",
            color: "#FFFFFF",
            textTransform: "capitalize",
            fontSize: "14px",
          }}
        >
          Criar assinatura
        </Button>
      </div>
      {value === "configuration" && (
        <Grid container spacing={3}>
          <Grid item xs style={{ marginTop: "20px", marginLeft: "20px" }}>
            <Configurations />
          </Grid>
        </Grid>
      )}
      {value === "dashboard" && (
        <Grid container spacing={3}>
          <Grid item xs style={{ marginTop: "20px", marginLeft: "20px" }}>
            <Dashboard />
          </Grid>
        </Grid>
      )}
      {value === "fechamento" && (
        <Grid container spacing={3} style={{ flex: 1, height: "100%" }}>
          <Grid item xs style={{ marginTop: "20px" }}>
            <Fechamento />
          </Grid>
        </Grid>
      )}
      {value === "extrato" && (
        <Grid container spacing={3} style={{ flex: 1, height: "100%" }}>
          <Grid item xs style={{ marginTop: "20px" }}>
            <Extrato />
          </Grid>
        </Grid>
      )}
      {value === "ranking" && (
        <Grid container spacing={3}>
          <Grid item xs style={{ marginTop: "20px" }}>
            <Ranking />
          </Grid>
        </Grid>
      )}
      {value === "subscribers" && (
        <Grid container spacing={3} style={{ flex: 1, height: "100%" }}>
          <Grid item xs style={{ marginTop: "20px" }}>
            <Subscribers />
          </Grid>
        </Grid>
      )}

      {parseInt(value, 10) === 0 && (
        <>
          <Grid container spacing={3} className={classes.filter}>
            <Grid item xs={2}>
              <Controller
                control={control}
                defaultValue={primeiroDia}
                name="dateInicio"
                as={({ onChange, value }) => (
                  <TextField
                    id="date"
                    label="Inicio"
                    type="date"
                    value={value}
                    className={classes.textField}
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      onChange(e.target.value);
                      setFirstData(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                control={control}
                defaultValue={moment(date).format("YYYY-MM-DD")}
                name="dateFim"
                as={({ onChange, value }) => (
                  <TextField
                    id="date"
                    label="Fim"
                    type="date"
                    value={value}
                    className={classes.textField}
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      onChange(e.target.value);
                      setLastData(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={8} style={{ display: "flex", alignItems: "center" }}>
              <Box
                display="flex"
                justifyContent="flex-end"
                style={{ width: "100%" }}
              >
                <Box justifyContent="flex-end">
                  <Button
                    variant="contained"
                    onClick={() => handleClick()}
                    color="primary"
                    disabled={disableButton}
                    style={{
                      background: "#CCD219",
                      fontSize: "14px",
                      textTransform: "capitalize",
                      fontWeight: "700",
                      width: "138px",
                      marginRight: "20px",
                    }}
                  >
                    Filtrar
                  </Button>

                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={loadingExport}
                    onClick={() => exporte()}
                    className={classes.exportButton}
                  >
                    Exportar
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs>
              <Paper className={classes.paper}>
                {dashboard ? (
                  <>
                    <Typography gutterBottom variant="subtitle1">
                      {dashboard?.appointmentsTotal}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      TOTAL DE BANHOS
                    </Typography>
                  </>
                ) : (
                  <Skeleton style={{ width: "100px" }} />
                )}
              </Paper>
            </Grid>

            <Grid item xs>
              <Paper className={classes.paper}>
                {dashboard ? (
                  <>
                    <Typography gutterBottom variant="subtitle1">
                      {dashboard?.frequency
                        ? dashboard?.frequency.toString().replace(".", ",")
                        : "-"}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      FREQUÊNCIA PET
                    </Typography>
                  </>
                ) : (
                  <Skeleton style={{ width: "100px" }} />
                )}
              </Paper>
            </Grid>
            <Grid item xs>
              <Paper className={classes.paper}>
                {dashboard ? (
                  <>
                    <Typography gutterBottom variant="subtitle1">
                      {dashboard?.totalPets ? dashboard?.totalPets : "-"}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      TOTAL PETS
                    </Typography>
                  </>
                ) : (
                  <Skeleton style={{ width: "100px" }} />
                )}
              </Paper>
            </Grid>
          </Grid>
          <TableContainer
            component={Paper}
            elevation={0}
            style={{ overflow: "initial" }}
          >
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Data</StyledTableCell>
                  <StyledTableCell style={{ width: 180 }}>
                    Cliente
                  </StyledTableCell>
                  <StyledTableCell>Pet</StyledTableCell>
                  <StyledTableCell>Serviço</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  {/* <StyledTableCell> </StyledTableCell> */}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {subscriptions?.map((item) => (
                  <TableRow key={item.customerFirstName}>
                    <StyledTableCell>
                      {moment(item?.appointmentDate).format("DD/MM/YYYY")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {`${item?.customerFirstName} ${item?.customerLastName}`}
                    </StyledTableCell>
                    <StyledTableCell>{item?.petName}</StyledTableCell>
                    <StyledTableCell>{item?.serviceName}</StyledTableCell>
                    <StyledTableCell>{item?.status}</StyledTableCell>
                    {/* <StyledTableCell >
                                            <Button
                                                    variant="contained"
                                                    onClick={() => {}}
                                                    color="primary"
                                                    disabled={disableButton}
                                                    style={{
                                                        background: "#CCD219",
                                                        fontSize: "14px",
                                                        textTransform: "capitalize",
                                                        fontWeight: "700",
                                                        width: "160px",
                                                        marginRight: "20px"
                                                    }}
                                                >
                                                    Ver agendamento
                                            </Button>
                                        </StyledTableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ justifyContent: "center" }}
            className={classes.pagination}
          >
            {!!pageAgendamentos &&
              !!subscriptions.length &&
              pageAgendamentos.totalPages > 1 && (
                <Pagination
                  count={pageAgendamentos.totalPages}
                  variant="outlined"
                  onChange={agendamentosUpdatePagination}
                />
              )}
          </Grid>
        </>
      )}

    </BasePage>
  );
}

export default Assinaturas;
